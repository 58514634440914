import React from "react";
import StepOneImage from "../img/stepOneImage.svg";
import StepTwoImage from "../img/stepTwoImage.svg";
import StepThreeImage from "../img/stepThreeImage.svg";
import StepFourImage from "../img/stepFourImage.svg";

export default function Header() {
  return (
    <div className="hidden sm:block">
      {/* Title */}
      <div className="px-6 pt-4 pb-2 text-center">
        <p className="text-2xl md:text-3xl font-bold">What is a SEQUEL?</p>
      </div>

      <div className="bg-header p-6 flex flex-row text-center text-sm lg:text-base">
        {/* Step 1 */}
        <div className="w-1/2 border-r border-primary">
          <div className="h-16">
            <p className="font-bold">Step 1</p>
            <p>Buys new for £100</p>
          </div>
          <div className="h-28 p-1 flex justify-center">
            <img
              src={StepOneImage}
              alt="Step one"
              className="object-fit relative"
            />
          </div>
        </div>

        {/* Step 2 */}
        <div className="w-1/2 border-r border-primary">
          <div className="h-16">
            <p className="font-bold">Step 2</p>
            <p>Sells for £70 on marketplace</p>
          </div>
          <div className="h-28 p-1 flex justify-center">
            <img
              src={StepTwoImage}
              alt="Step two"
              className="object-fit relative"
            />
          </div>
        </div>

        {/* Step 3 */}
        <div className="w-1/2 border-r border-primary">
          <div className="h-16">
            <p className="font-bold">Step 3</p>
            <p>
              Brand gets cash. Seller gets £70 gift card.
            </p>
          </div>
          <div className="h-28 p-1 flex justify-center">
            <img
              src={StepThreeImage}
              alt="Step three"
              className="object-fit relative"
            />
          </div>
        </div>

        {/* Step 4 */}
        <div className="w-1/2">
          <div className="h-16">
            <p className="font-bold">Step 4</p>
            <p>
              Customer uses gift card and cash to purchase new item
            </p>
          </div>
          <div className="h-28 p-1 flex justify-center">
            <img
              src={StepFourImage}
              alt="Step four"
              className="object-fit relative"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
