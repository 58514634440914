import React, { useState } from "react";
import InputForm from "./InputForm";
import { RunCalculator } from "../utils/RunCalculator";
import OutputChart from "./OutputChart";

export default function Calculator() {
  // Get user data from InputForm.js
  const [formData, setFormData] = useState("");

  const getFormData = ({
    rrpSalePrice,
    rrpMargin,
    storeCreditPercent,
    costPerMonth,
    sequelsEachMonth,
  }) => {
    setFormData({
      rrpSalePrice,
      rrpMargin,
      storeCreditPercent,
      costPerMonth,
      sequelsEachMonth,
    });

    // RunCalculator.js to calculate ROI
    RunCalculator(
      {
        rrpSalePrice,
        rrpMargin,
        storeCreditPercent,
        costPerMonth,
        sequelsEachMonth,
      },
      { getResults }
    );
  };

  // Get results from RunCalculator.js
  const [results, setResults] = useState({
    costPerMonth: 2000,
    sequelCashGenerated: 4952,
    sequelsRequired: 40,
  });
  const getResults = ({
    costPerMonth,
    sequelCashGenerated,
    sequelsRequired,
  }) => {
    setResults({ costPerMonth, sequelCashGenerated, sequelsRequired });
  };

  return (
    <div className="min-h-fit">
      {/* Title area */}
      <div className="pt-10 md:pt-6 lg:pt-8 text-center">
        <p className="text-lg font-bold">SEQUEL Returns Calculator</p>
      </div>

      {/* Key Metrics - max md */}
      <div className="md:hidden text-center text-xs py-5 px-10">
        <p className="font-bold underline">Key metrics</p>
        <p className="text-sm">
          Please use the table below to calculate the estimated savings and ROI
          that your organisation can gain by implementing SEQUELS.
        </p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2">
        {/* Input form area */}
        <div className="m-2 sm:m-10 md:m-5">
          <div className="bg-metrics border border-metrics rounded h-full">
            <InputForm getFormData={getFormData} />
          </div>
        </div>

        {/* Chart area */}
        <div className="m-2 md:m-2">
          <div className="border rounded shadow-xl md:border-0 md:shadow-none h-full">
            <OutputChart dataToChart={results}/>
          </div>
        </div>
      </div>
    </div>
  );
}
