import React from "react";
import { PopupButton } from "react-calendly";

export default function Footer() {
  return (
    <div className="px-6 md:px-8 py-5 md:pb-4 text-center">
      <PopupButton
        className="bg-secondary p-1 md:p-2 rounded uppercase text-white font-bold"
        url="https://calendly.com/kennethsequel/30min"
        rootElement={document.getElementById("root")}
        text="Book a demo"
      />
      <div className="sm:hidden px-6 py-8 pb-2 text-center">
        <p className="text-lg md:text-2xl font-bold">What is a SEQUEL?</p>
        <p>A SEQUEL is......</p>
      </div>
    </div>
  );
}
