import SequelLogo from "../img/logo.svg";
import { PopupButton } from "react-calendly";

export default function NavBar() {
  return (
    <div className="p-8 bg-primary">
      <div className="flex justify-between">
        {/* SEQUEL Logo */}
        <div className="w-auto sm:w-1/2 flex md:justify-center">
          <div className="relative md:w-[24rem] lg:w-[32rem]">
            <h1>
              <a href="/">
                <img src={SequelLogo} alt="Sequel" className="h-3 md:h-5" />
              </a>
            </h1>
          </div>
        </div>

        {/* Book a Demo button */}
        <div className="w-auto sm:w-1/2 flex justify-end md:justify-start text-white">
          <div className="relative flex gap-8 justify-end md:w-[24rem] lg:w-[32rem]">
            <PopupButton
              className="uppercase text-xs md:text-sm"
              url="https://calendly.com/kennethsequel/30min"
              rootElement={document.getElementById("root")}
              text="Book a demo"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
