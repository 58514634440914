const RunCalculator = (
  {
    rrpSalePrice,
    rrpMargin,
    storeCreditPercent,
    costPerMonth,
    sequelsEachMonth,
  },
  { getResults }
) => {
  // BACKEND (MANUAL) INPUTS (required variables to calculate roi)
  const secondSalePercent = 70;
  const transactionSequel = 10;
  const transactionBrand = 20;
  const newPurchaseUplit = 2.5;

  // BACKEND FUNCTIONS
  const rrpBrandProfit = (rrpMargin / 100) * rrpSalePrice;
  console.log(`rrpBrandProfit: ${rrpBrandProfit}`);

  const secondSalePrice = rrpSalePrice * (secondSalePercent / 100);
  console.log(`secondSalePrice: ${secondSalePrice}`);

  const storeCreditValue =
    (secondSalePrice -
      secondSalePrice * (transactionBrand / 100 + transactionSequel / 100)) *
    (storeCreditPercent / 100);
  console.log(`storeCreditValue: ${storeCreditValue}`);

  // profitSequel not used in calculation
  // const profitSequel = secondSalePrice * transactionSequel;

  // netSeller not used in calculation
  //   let netSeller = () => {
  //     if (storeCreditSwitch === true) {
  //       netSeller = 0;
  //     } else {
  //       netSeller =
  //         secondSalePrice -
  //         secondSalePrice * (transactionBrand + transactionSequel);
  //     }
  //   };

  const profitBrandMargin = (transactionBrand / 100) * secondSalePrice;
  console.log(`profitBrandMargin: ${profitBrandMargin}`);

  const netBrandMargin =
    secondSalePrice -
    secondSalePrice * (transactionBrand / 100 + transactionSequel / 100);
  console.log(`netBrandMargin: ${netBrandMargin}`);

  const lessStoreCredit = 0 - storeCreditValue;

  const netToBrand = profitBrandMargin + netBrandMargin + lessStoreCredit;
  console.log(`netToBrand: ${netToBrand}`);

  const newPurchaseSpend = newPurchaseUplit * storeCreditValue;
  console.log(`newPurchaseSpend: ${newPurchaseSpend}`);

  const netCashSpend = newPurchaseSpend - 0;
  console.log(`netCashSpend: ${netCashSpend}`);

  const profitForBrand = netCashSpend * (rrpMargin / 100);
  console.log(`profitForBrand: ${profitForBrand}`);

  const sequel = rrpBrandProfit + netToBrand + profitForBrand;
  console.log(`sequel: ${sequel}`);

  // OUTPUTS
  const sequelLinier = Math.round((sequel - rrpBrandProfit) * 100) / 100;
  const uplift = Math.round((rrpBrandProfit / sequelLinier) * 100);
  const roiPerSequel = Math.round(
    ((sequel - rrpBrandProfit) / rrpBrandProfit) * 100
  );
  const sequelsRequired = Math.round(costPerMonth / sequelLinier);
  const sequelCashGenerated =
    Math.round(sequelsEachMonth * sequelLinier * 100) / 100;

  console.log(
    `sequelLinier ${sequelLinier} : uplift ${uplift} : roiPerSequel ${roiPerSequel} : sequelsRequired ${sequelsRequired} : sequelCashGenerated ${sequelCashGenerated}}`
  );

  getResults({ costPerMonth, sequelCashGenerated, sequelsRequired });
};

export { RunCalculator };
