import React, { useState } from "react";
import Pen from "../img/pen-icon.svg";

export default function InputForm({ getFormData }) {
  // Defines calcualtor data
  const [inputData, setInputData] = useState({
    rrpSalePrice: 100,
    rrpMargin: 30,
    storeCreditPercent: 110,
    costPerMonth: 2000,
    sequelsEachMonth: 40,
  });

  // Makes inputs editable
  function handleChange(evt) {
    const value = evt.target.value;
    setInputData({
      ...inputData,
      [evt.target.name]: value,
    });
  }

  // Handles form submit
  const handleSubmit = async (event) => {
    event.preventDefault();

    getFormData({
      ...inputData,
    });
  };

  return (
    <div className="px-3 md:px-2 lg:px-8 py-4 md:py-6 h-fit text-center text-sm lg:text-base">
      {/* Key Metrics - min md */}
      <div className="hidden md:block">
        <p className="font-bold underline">Key metrics</p>
        <p className="text-sm">
          Please use the table below to calculate the estimated savings and ROI
          that your organisation can gain by implementing SEQUELS.
        </p>
      </div>

      <form onSubmit={handleSubmit}>
        {/* RRP Sale Price */}
        <div className="p-2 md:p-4 flex flex-row">
          <div className="w-3/4 text-left">
            <label>RRP Sale Price (£)</label>
          </div>
          <div className="w-1/4 text-right">
            <input
              type="text"
              name="rrpSalePrice"
              value={inputData.rrpSalePrice}
              onChange={handleChange}
              className="text-right w-full pr-1 border border-secondary rounded"
            />
          </div>
        </div>

        {/* RRP Margin % */}
        <div className="p-2 md:p-4 flex flex-row">
          <div className="w-3/4 text-left">
            <label>RRP Margin %</label>
          </div>
          <div className="w-1/4 text-right">
            <input
              type="text"
              name="rrpMargin"
              value={inputData.rrpMargin}
              onChange={handleChange}
              className="text-right w-full pr-1 border border-secondary rounded"
            />
          </div>
        </div>

        {/* Store Credit % */}
        <div className="p-2 md:p-4 flex flex-row">
          <div className="w-3/4 text-left">
            <label>Store Credit</label>
          </div>
          <div className="w-1/4 text-right">
            <input
              type="text"
              name="storeCreditPercent"
              value={inputData.storeCreditPercent}
              onChange={handleChange}
              className="text-right w-full pr-1 border border-secondary rounded"
            />
          </div>
        </div>

        {/* Cost of SEQUEL per month */}
        <div className="p-2 md:p-4 flex flex-row">
          <div className="w-2/3 text-left">
            <label>Cost of SEQUEL per month (£)</label>
          </div>
          <div className="w-1/3 text-right">
            <select
              type="text"
              name="costPerMonth"
              value={inputData.costPerMonth}
              onChange={handleChange}
              className="w-full border border-secondary rounded"
            >
              <option value="500">Bronze £500</option>
              <option value="2000">Silver £2,000</option>
              <option value="5000">Gold £5,000</option>
            </select>
          </div>
        </div>

        {/* Number of sequels each month - Manual Input */}
        <div className="p-2 md:p-4 flex flex-row">
          <div className="w-3/4 text-left">
            <label>Number of SEQUELS each month</label>
          </div>
          <div className="w-1/4 flex border bg-white border-secondary rounded text-secondary font-bold">
            <input
              type="text"
              name="sequelsEachMonth"
              value={inputData.sequelsEachMonth}
              onChange={handleChange}
              className="text-right w-full"
            />
            <div className="w-7 flex m-1 justify-center">
              <img src={Pen} alt="Pen icon"/>
            </div>
          </div>
        </div>

        {/* Number of sequels each month - Slider */}
        <div className="px-2">
          <input
            className="w-full pt-5 pb-3"
            type="range"
            name="sequelsEachMonth"
            value={inputData.sequelsEachMonth}
            min="40"
            max="50000"
            onChange={handleChange}
          />
          <div className="-mt-2 flex w-full justify-between text-sm">
            <span>40</span>
            <span>50,000</span>
          </div>
        </div>

        {/* Submit button */}
        <div className="p-2 md:p-4">
          <button className="bg-secondary text-white font-bold p-2 md:p-2 rounded">
            Generate Results
          </button>
        </div>
      </form>
    </div>
  );
}
