import Calculator from "./components/Calculator";
import Footer from "./components/Footer";
import MainHeader from "./components/MainHeader";
import NavBar from "./components/NavBar";

export default function App() {
  return (
    <div className="font-sans bg-white text-primary">
      <NavBar />
      <MainHeader/>
      <Calculator />
      <Footer />
    </div>
  );
}
