import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
Chart.register(...registerables, ChartDataLabels);

const OutputChart = ({ dataToChart }) => {
  const labels = ["SEQUEL PPM", "Cash Generated"];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "data",
        backgroundColor: ["#06213E", "#5FB66E"],
        borderColor: "rgb(255, 99, 132)",
        data: [dataToChart.costPerMonth, dataToChart.sequelCashGenerated],
      },
    ],
  };

  // Desktop chart display options
  const desktopOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: { enabled: false },
      datalabels: false,
    },
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return ` £${value.toLocaleString("en")}`;
          },
          font: {
            family: "strawford",
            size: 15,
          },
          color: "#05203E",
        },
        grid: {
          color: "#05203E",
        },
        border: {
          dash: [4, 6],
        },
      },
      x: {
        display: false,
      },
    },
  };

  // Mobile chart display options
  const mobileOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: { enabled: false },
      datalabels: {
        color: "#fff",
        anchor: "end",
        align: "start",
        padding: "-3",
        font: {
          size: 20,
        },
        formatter: function (value, context) {
          return ` £${value.toLocaleString("en")}`;
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value) {
            return ` £${value.toLocaleString("en")}`;
          },
          font: {
            family: "strawford",
            size: 15,
          },
          color: "#05203E",
        },
        grid: {
          display: false,
        },
        border: {
          display: false,
        },
      },
      x: {
        display: false,
      },
    },
  };

  return (
    <>
      <div className="grid grid-cols-1 sm:grid-cols-2">
        {/* No of SEQUELS */}
        <div className="justify-center text-center pt-3">
          <p className="font-bold text-sm">Number of SEQUELS to break even</p>
          <div className="text-right w-2/3 md:w-11/12 pr-1 border border-primary rounded font-bold text-xl md:text-2xl lg:text-3xl m-auto">
            {dataToChart.sequelsRequired}
          </div>
        </div>

        {/* Cash Generated */}
        <div className="justify-center text-center py-3">
          <p className="font-bold text-sm">Cash generated from SEQUELS</p>
          <div className="text-right w-2/3 md:w-11/12 pr-1 border border-secondary rounded text-secondary font-bold text-xl md:text-2xl lg:text-3xl m-auto">{`£ ${dataToChart.sequelCashGenerated.toLocaleString(
            "en"
          )}`}</div>
        </div>
      </div>

      {/* Bar Chart - Desktop*/}
      <div className="hidden md:flex h-4/5">
        <Bar data={data} options={desktopOptions} />
      </div>

      {/* Bar Chart - Mobile*/}
      <div className="md:hidden h-3/5">
        <Bar data={data} options={mobileOptions} />
      </div>
      <div className="h-10 flex">
        <div className="flex justify-end sm:justify-center w-1/2">
          <div className="w-3 h-3 bg-primary m-2"></div>
          <div className="my-1 text-sm">SEQUEL PPM</div>
        </div>
        <div className="flex justify-center w-1/2">
          <div className="w-3 h-3 bg-secondary m-2"></div>
          <div className="my-1 text-sm">Cash Generated</div>
        </div>
      </div>
    </>
  );
};

export default OutputChart;
